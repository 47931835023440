@import '../../sizes.scss';
@import '../../common/colors.scss';

.topbar {
    display: flex;
    height: $topbar-height;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.07), 0px 0.893452px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 0.266004px 5.32008px rgba(0, 0, 0, 0.0282725);
    border: 1px solid $light-grey;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 60px;
    height: 60px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $right-menu-width-closed;
}
