@import '../../sizes.scss';

$padding-left: 10px;

.colorSpan {
    display: inline-block;
    position: absolute;
    width: 0.5em;
    height: 1em;
    right: 0;
}

.legendEntry {
    padding-right: 0.8em;
    line-height: 1.2;
    padding-bottom: 0.1em;
    position: relative;
}

.legend {
    text-align: right;
    list-style: none;
    padding: 0.3em 0 0 0;
    margin: 0;
}

.legendWrapper {
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    padding-left: $padding-left;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    width: $legend-width;
}

.legendTitle {
    font-weight: 500;
    font-size: 14px;
    color: #5e6a7d;

    text-align: center;
    width: calc($legend-width - $padding-left);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
