@import '../../sizes.scss';

.legendWrapper {
    background: #fff;
    width: $legend-width;
    height: 100%;
}

.entry {
    margin: auto;
}
