@import '../../../common/style.scss';

.title {
    @include ellipsis;
    max-width: 300px;
    margin-right: auto;
    font-weight: 500;
    font-size: 14px;
}

.headerWrapper {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.key {
    font-weight: 500;
    font-size: 13px;
}

.keyTd {
    @include ellipsis;
    max-width: 5em;

    vertical-align: top;

    p {
        @include ellipsis;
    }
}
