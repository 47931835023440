.header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
}

.title {
    font-weight: 500;
    font-size: 24px;
    padding-bottom: 20px;
}

.totalView {
    font-weight: 500;
    font-size: 12px;
}
