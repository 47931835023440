.logoWrapper {
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
}

.input {
    min-width: 300p;
}
