.title {
    font-weight: 400;
    font-size: 16px;
    text-transform: none;
    padding-left: 9px;
}

.box {
    padding-right: 14px;
}

.button {
    padding: 13px;
    min-width: unset;
    // TODO: Remove?
    // border: 1.5px solid #eaeaea;
}
