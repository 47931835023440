@import '../../sizes.scss';

.container {
    background-color: #fff;
    position: absolute;
    top: $topbar-height;
    right: $right-menu-width-closed;
    bottom: 0;
    left: $legend-width;
}

.containerOpenMenu {
    right: $right-menu-width-open;
}

.visualization {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
