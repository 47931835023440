@import '../../sizes.scss';
@import '../../common/colors.scss';

.wrapper {
    background: #ffffff;
    border: 1px solid $light-grey;
    border-top: 0;
    box-shadow: 0px 4px $right-menu-width-closed rgba(0, 0, 0, 0.07), 0px 0.893452px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 0.266004px 5.32008px rgba(0, 0, 0, 0.0282725);
    position: absolute;
    right: 0;
    height: calc(100% - $topbar-height);
    display: flex;
}

.menuItems {
    width: $right-menu-width-closed;
    float: right;
}

.menu {
    overflow-y: auto;
    overflow-x: hidden;
}