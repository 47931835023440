@import '../colors.scss';

.tooltip {
    background: $grey-50;
    border: 1px solid $grey-300;
    border-radius: 8px;
    color: $mid-grey;

    &::before {
        border: 1px solid $grey-300;
        border-top: 0;
        border-left: 0;
    }
}

.verticalLine {
    border-left: 1px solid $light-grey;
    height: 14px;
    position: absolute;
    top: 20px;
}

.sliderMarkLabelWrapper {
    & > span {
        top: 35px;
    }
}
