@import './common.module.scss';

.palletPreview {
    width: 20px;
    border-radius: 4px;
}

.palletName {
    margin-left: 10px;
}
