@import '../../../common/style.scss';

.gridItem {
    text-align: center;
}

.key {
    @include ellipsis();

    padding: 0 15px;
    font-weight: 500;
    font-size: 11px;
}

.value {
    @include ellipsis();
    font-weight: 400;
    font-size: 19px;
}
