@import './common.module.scss';

.layoutWrapper {
    @extend .commonWrapper;
    padding-top: 20px;
}

.layoutName {
    font-weight: 400;
    font-size: 14px;
    text-transform: none;
    padding: 0 11px;
}
