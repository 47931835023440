.filtersWrapper {
    padding-top: 20px;
    padding-right: 26px;
    padding-left: 26px;
    width: 800px;
}

.resetChanges {
    font-weight: 400;
    font-size: 14px;
    text-transform: none;
    padding-left: 10px;
}

.title {
    font-weight: 500;
    font-size: 20px;
}

.resetAll {
    background: #fafaf9;
    margin-left: auto;
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.endButtonsWrapper {
    margin-bottom: 8px;
    float: right;
}

@mixin button {
    text-transform: none;
    box-shadow: none;
    font-weight: 700;
    font-size: 14px;
    width: 128px;
}

.closeButton {
    margin-right: 16px;
    @include button();
}

.applyButton {
    @include button();
}
