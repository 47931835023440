.name {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
}

.email {
    font-weight: 500;
    font-size: 14px;
    color: rgba(26, 26, 26, 0.5);
}

.menuItem {
    font-weight: 500;
    font-size: 16px;
    color: rgba(26, 26, 26, 0.7);
}
