@import '../../sizes.scss';

.box {
    top: $topbar-height;
    right: $right-menu-width-closed;
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgba(52, 82, 106, 0.4);
}
