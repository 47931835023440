@import '../../sizes.scss';

.box {
    top: $topbar-height;
    right: $right-menu-width-closed;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.title {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.15px;
}

.link {
    cursor: pointer;
    color: #43a8e1;
}
