.fieldSelectRow {
    display: flex;
}

.selectValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.deleteButtonWrapper {
    display: flex;
    align-items: center;
}
