.naValue {
    font-style: italic;
    color: gray;
}

.detailValue {
    color: #5e6a7d;
    font-weight: 400;
    font-size: 13px;

    p {
        margin: 0;
        line-height: unset;
    }
    word-break: break-word;
}
